export const useEnter = (el) => {
  el.style.height = 'auto';
  const {height} = getComputedStyle(el);
  el.style.height = 0;
  getComputedStyle(el);
  setTimeout(() => {
    el.style.height = height;
  });
};

export const useAfterEnter = (el) => {
  el.style.height = 'auto';
};

export const useLeave = (el) => {
  el.style.height = getComputedStyle(el).height;
  getComputedStyle(el);
  setTimeout(() => {
    el.style.height = 0;
  });
};
